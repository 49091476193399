<template>
  <div v-frag>
    <hero-with-image :title="brand.brandName" subtitle="" :image="brand.imagePath"></hero-with-image>
    <v-container class="py-5">
      <page-title title="services.simpleRepair"></page-title>
    </v-container>
    <v-container class="py-10" v-if="brandId === 13">
      <v-row justify="center" align-content="center">
        <v-img :src="`${publicPath}images/D2D-Xiaomi-banner.jpg`" width="100%" max-width="959"></v-img>
      </v-row>
      <v-row justify="center" align-content="center" class="mt-10">
        <div v-html="$t('xiaomiRepairText')"></div>
      </v-row>
    </v-container>
    <!--<v-container class="py-8" v-if="brandId === 12">
      <v-row justify="center" align-content="center" class="mt-10">
        <v-col cols="12" ms="10">
          <div class="font-22 text-center">
            Samsung készülékek garanciális javítási futár szolgáltatást a Samsung központi telefonos ügyfélszolgálatán
            lehet igényelni,<br> a <b><a href="tel:+36807267864" style="color: rgba(0,0,0,.87);">0680 726 7864</a> (0680 SAMSUNG)</b> telefonszámon.
          </div>
        </v-col>
      </v-row>
    </v-container>-->
    <v-container class="py-10">
      <template v-if="brandId === 2">
        <v-row justify="center" id="ac">
          <template v-for="(service, index) in brand.availableService">
            <v-col cols="12" md="auto" :key="index" :class="$vuetify.breakpoint.smAndUp ? 'px-10': ''" v-if="[4, 82].includes(service.id)">
              <brand-service-card :service="service" :is-right="!(index % 2 === 0)"></brand-service-card>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-else>
        <v-row justify="center" id="oc">
          <template v-for="(service, index) in brand.availableService">
            <v-col cols="12" md="auto" :key="index" :class="$vuetify.breakpoint.smAndUp ? 'px-10': ''">
              <brand-service-card :service="service" :is-right="!(index % 2 === 0)"></brand-service-card>
            </v-col>
          </template>
        </v-row>
      </template>
    </v-container>
    <v-container class="whiteFA mt-10" fluid v-if="brand && brand.placeForText">
      <v-container class="py-10">
        <v-row>
          <v-col cols="12" md="7">
            <div v-html="brand.generalInfo"></div>
          </v-col>
          <v-col cols="12" md="5">
            <v-row class="mx-0 fill-height" justify="center" align="center">
              <v-col cols="12">
                <v-card class="bra-20">
                  <v-img :src="`${publicPath}images/Jenny_Pink_Front@900.jpg`"></v-img>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="bra-20">
                  <v-img :src="`${publicPath}images/Jenny_Pink_Front@900.jpg`"></v-img>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="bra-20">
                  <v-img :src="`${publicPath}images/Jenny_Pink_Front@900.jpg`"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import HeroWithImage from "../../../components/titles/HeroWithImage.vue";
import PageTitle from "../../../components/titles/PageTitle.vue";
import BrandServiceCard from "../../../components/services/BrandServiceCard.vue";

export default {
  name: 'ExternalBrandHomePageNew',
  components: {
    HeroWithImage, PageTitle, BrandServiceCard
  },
  async beforeMount() {
    this.brand = await this.getBrand();
    // console.log('ExternalBrandHomePage - this.brand: ', this.brand);
  },
  props: {
    brandId: {
      type: Number,
      default: 999,
    },
    brandName: {
      type: String,
      default: 'EMPTY',
    },
  },
  data() {
    return {
      brand: {},
    };
  },
  methods: {
    async getBrand() {
      return await this.$store.dispatch('getBrandById', this.brandId);
    },
  },
};
</script>

<style scoped>

</style>
