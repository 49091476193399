<template>
  <div class="brandServiceCard-wrapper">
    <v-card height="410" width="280"
            :class="isRight ? 'brandServiceCard-bgCard-right info darken-3' : 'brandServiceCard-bgCard-left primary darken-3'"
            class="brandServiceCard-bgCard bra-20" style="position: absolute">
      <v-row class="mx-0 px-5 fill-height" :justify="isRight ? 'end' : 'start'" align="center">
        <div style="height: 350px; width: 112px;">
          <v-img :src="isRight ? `${publicPath}images/mobilfutarRight.png` : `${publicPath}images/mobilfutarLeft.png`"></v-img>
        </div>
      </v-row>
    </v-card>
    <v-card max-width="370" max-height="350" height="350" class="px-10 pt-10 pb-7 bra-20" style="position: relative">
      <v-row class="mx-0 fill-height" align-content="space-between" justify="start" :class="isRight ? 'mr-8 pr-10' : 'ml-8 pl-10'">
        <v-col cols="12" class="pt-8 px-0">
          <span class="display-1 font-weight-bold text-uppercase">
            {{ service.warrantyTypeName.substring(0, service.warrantyTypeName.lastIndexOf(' ')) }}
          </span><br>
          <span class="font-32 font-weight-light text-uppercase">
            {{ service.warrantyTypeName.substring(service.warrantyTypeName.lastIndexOf(' '), service.warrantyTypeName.length + 1).trim() }}
          </span>
        </v-col>
        <v-col cols="12" class="pa-0 font-18">
          <!--<v-row :justify="isRight ? 'start' : 'end'" class="mx-0" v-if="service.id === 22">
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="primary" fab small elevation="3">
                    <v-icon color="white">
                      mdi-phone
                    </v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    Samsung futárigénylés
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <a href="tel:+366807267864">+36-80/726-7864</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>-->
          <!--<v-divider></v-divider>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Samsung futárigénylés
                </v-list-item-title>
                <v-list-item-subtitle>
                  <a href="tel:+36807267864">06-80/726-7864</a>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="primary" fab small elevation="3" @click="callSamsung">
                  <v-icon color="white">
                    mdi-phone
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          Samsung futárigénylés: <a href="tel:+366807267864">+36-80/726-7864</a>-->
          <!--</v-row>-->
      </v-col>
      <v-col cols="12" class="pa-0">
        <!--<template v-if="service.id === 22">
          <div class="body-2">
            Samsung készülékek garanciális javítási futár szolgáltatást a Samsung központi telefonos ügyfélszolgálatán
            lehet igényelni, a 0680 726 7864 (0680 SAMSUNG) telefonszámon.
          </div>
        </template>-->
          <template>
            <v-row :justify="isRight ? 'start' : 'end'" class="mx-0">
              <v-btn class="primary" @click="newOrderFormPage(service.url)">
                {{ $t('button.next') }}
              </v-btn>
            </v-row>
          </template>
        </v-col>
      </v-row>
      <div class="brandServiceCard-image" :class="isRight ? 'brandServiceCard-image-right' : 'brandServiceCard-image-left'">
        <v-img :src="`${publicPath}${service.imagePath}`"></v-img>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'BrandServiceCard',
  components: {
    //
  },
  props: {
    service: {
      type: Object,
    },
    isRight: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      //
    };
  },
  methods: {
    newOrderFormPage(url) {
      if(this.service.isExternalLink) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },
    callSamsung() {
      window.open('tel:+366807267864', '_self');
    },
  },
};
</script>

<style scoped>

</style>
